<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <!--<div align="center" style="background-color: #FAFAFA">
              <v-img  :src="card.src" style="max-height: 120px; max-width: 120px"/>

            </div>-->
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />

          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-medal-outline',
          title: 'Certificación',
          text: 'Es el proceso de evaluación a que voluntariamente se somete un médico después de haber cursado y aprobado un programa de especialización (residencia médica avalada por una institución de enseñanza superior) en alguna rama de la medicina.',
          src: require('@/assets/compac/cert.svg'),

        },
        {
          icon: 'mdi-file-sync-outline',
          title: 'Recertificación',
          text: 'Es el proceso de evaluación a un médico especialista certificado en alguna rama de la medicina que permite razonablemente asegurar que ha continuado con su preparación, que mantiene al día sus conocimientos y que sabe utilizar la tecnología para atender eficazmente y con calidad a sus pacientes.',
        },
        {
          icon: 'mdi-account-school-outline',
          title: 'Educación Continua',
          text: 'Este Comité tiene como misión esencial el coadyuvar en la labor conjunta del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio en todas aquellas actividades académicas que favorezcan la actualización de todos aquellos miembros en los diferentes temas de la especialidad.',
        },
        {
          icon: 'mdi-account-group',
          title: 'Patólogos Clínicos',
          text: 'Esta sección le permitirá consultar la lista actualizada de los Patólogos Clínicos certificados por El Consejo Mexicano de Patología Clínica y Medicina de Laboratorio A.C.',
        },
      ],
    }),
  }
</script>
